import { Box, Button, Card, CardContent, Grid, TextField, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../../Lib/ui-kit/assets/logo-colour.png';
import Layout from '../../components/Layout';

interface RegisterForm {
  username: string;
  password: string;
  passwordConfirm: string;
}

const isFormValid = (form: Partial<RegisterForm>): form is RegisterForm => {
  return (
    form.username !== undefined && form.password !== undefined && form.passwordConfirm !== undefined
  );
};

const register = async (form: RegisterForm) => {
  // TODO: implement user registration
  return Promise.resolve(form);
};

const Register = () => {
  const [form, setForm] = useState<Partial<RegisterForm>>({});
  const history = useHistory();

  return (
    <Layout title="Register">
      <Card>
        <CardContent>
          <Box m={1}>
            <Grid container direction="row" justify="center" alignItems="center">
              <img
                src={logo}
                alt="Blitzm"
                style={{ height: 32, width: 32, marginRight: 10, marginLeft: -10 }}
              />
              <Typography variant="h3">Register</Typography>
            </Grid>
          </Box>

          <Grid container direction="column" justify="center" alignItems="center">
            <TextField
              label="Username"
              onChange={(e) => setForm({ ...form, username: e.target.value })}
            />
            <TextField
              label="Password"
              type="password"
              onChange={(e) => setForm({ ...form, password: e.target.value })}
            />
            <TextField
              label="Confirm Password"
              type="password"
              onChange={(e) => setForm({ ...form, passwordConfirm: e.target.value })}
              error={form.password !== form.passwordConfirm}
            />
            <div>
              <Button
                color="primary"
                style={{ marginTop: 20, marginRight: 10 }}
                onClick={async () => {
                  if (isFormValid(form)) {
                    await register(form);
                  }
                }}
                disabled={isFormValid(form)}
              >
                Register as Admin
              </Button>
              <Button
                color="primary"
                style={{ marginTop: 20 }}
                onClick={async () => {
                  if (isFormValid(form)) {
                    await register(form);
                    history.push('/');
                  }
                }}
                disabled={isFormValid(form)}
              >
                Register as Patient
              </Button>
            </div>
            <Button
              color="primary"
              style={{ marginTop: 10 }}
              onClick={async () => history.push('/')}
            >
              Back
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Layout>
  );
};

export default observer(Register);
