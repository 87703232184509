import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

interface Props {
  title: string;
  to: string;
  icon: React.ReactNode;
}

const RouteListItem = (props: Props) => {
  const match = useRouteMatch({
    path: props.to,
    exact: true,
  });

  // eslint-disable-next-line react/prop-types
  const LinkWrapper = (wrapperProps: unknown) => <Link to={props.to} {...wrapperProps} />;

  return (
    <ListItem button component={LinkWrapper} selected={match?.isExact}>
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText primary={props.title} />
    </ListItem>
  );
};

export default RouteListItem;
