import React from 'react';
import { observer } from 'mobx-react';
import { Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout';

const Home = () => {
  return (
    <Layout title="Home">
      <Typography variant="h1">Blitzm Booking SaaS</Typography>

      <Link to="/documentation">
        <Button color="primary">Documentation</Button>
      </Link>

      <Link to="/login">
        <Button color="primary">Login</Button>
      </Link>
    </Layout>
  );
};

export default observer(Home);
