import { Button, Card, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useHistory } from 'react-router';
import Layout from '../../components/Layout';

const LandingPage = () => {
  // TODO: implement the clinic analogue type in the new api
  // const [clinics, setClinics] = useState<any>([]);

  const history = useHistory();

  // const getClinics = async () => {
  //   // TODO: implement the new clinic api
  //   const clinicsFromApi = await Promise.resolve([]);
  //   // setClinics(clinicsFromApi);
  // };

  return (
    <Layout title="Landing Page">
      <Typography variant="h2"> Welcome to blitzm booking demo </Typography>
      <Card>
        <Button onClick={async () => history.push(`/clinics/EXAMPLE_CLINIC/`)}>
          See demo clinic
        </Button>
        <Button onClick={async () => history.push(`/clinics/new/`)}>Register your clinic</Button>
        <br />
        <Typography variant="h2">Your clinics</Typography>
      </Card>
    </Layout>
  );
};

export default observer(LandingPage);
