import { Divider, List } from '@material-ui/core';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import React from 'react';
import RouteListItem from '../../../Lib/ui-kit/RouteListItem';

const TenantDashboardNav = (
  <>
    <List>
      <RouteListItem title="Locations" to="/dashboard" icon={<HomeIcon />} />
      <RouteListItem title="Api Keys" to="/dashboard/api-keys" icon={<DeviceHubIcon />} />
    </List>
    <Divider />
    <List>
      <RouteListItem title="Settings" to="/todo" icon={<SettingsIcon />} />
    </List>
  </>
);

export default TenantDashboardNav;
