import { Card, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import Layout from '../../../components/Layout';

const NewClinic = () => {
  return (
    <Layout title="NewClinic">
      <Card>
        <Typography variant="h1">Register your clinic</Typography>
      </Card>
    </Layout>
  );
};

export default observer(NewClinic);
