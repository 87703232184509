import { observer } from 'mobx-react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { sharedUserService } from '../../../Lib/authentication/UserService';
import LoginCard from '../../../Lib/ui-kit/LoginCard';
import Layout from '../../components/Layout';

const Login = () => {
  const history = useHistory();
  return (
    <Layout title="Login">
      <LoginCard
        onLogin={async (username: string, password: string) => {
          await sharedUserService.signIn(username, password);
          history.push('/dashboard');
        }}
        onClickRegister={() => {
          history.push('/register');
        }}
      />
    </Layout>
  );
};

export default observer(Login);
