import { Box, Button, Card, CardContent, Grid, TextField, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { FormEvent, useState } from 'react';
import logo from './assets/logo-colour.png';

interface Props {
  onLogin: (username: string, password: string) => void;
  onClickRegister?: () => void;
}

const LoginCard = (props: Props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const submitDisabled = () => {
    return username === '' || password === '';
  };

  const formSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!submitDisabled()) {
      props.onLogin(username, password);
    }
  };

  return (
    <Card>
      <CardContent>
        <Box m={1}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Box m={1}>
              <img src={logo} alt="Blitzm" style={{ height: 35 }} />
            </Box>
            <Typography variant="h3">Login</Typography>
          </Grid>
        </Box>
        <form onSubmit={formSubmit}>
          <Grid container direction="column" alignItems="center" spacing={4}>
            <Grid container item xs={12} md={6} direction="column" spacing={2}>
              <Grid item>
                <TextField
                  fullWidth
                  label="Username"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label="Password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} md={6} direction="column">
              <Button type="submit" color="primary" disabled={submitDisabled()}>
                Login
              </Button>
              {props.onClickRegister && (
                <Button color="primary" onClick={props.onClickRegister}>
                  Register
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default observer(LoginCard);
