import { Button } from '@material-ui/core';
import React, { useState } from 'react';

interface Props {
  value: React.ReactNode;
}

const HiddenField = (props: Props) => {
  const [hidden, setHidden] = useState(true);

  if (!hidden) {
    return <span>{props.value}</span>;
  }

  return (
    <Button variant="outlined" onClick={() => setHidden(false)}>
      Show
    </Button>
  );
};

export default HiddenField;
