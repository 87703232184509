import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthRoute } from '../../Lib/components/AuthRoute';
import Calendar from '../pages/Calendar/Calendar';
import NewClinic from '../pages/Clinic/New/NewClinic';
import { Documentation } from '../pages/Documentation/Documentation';
import Home from '../pages/Home/Home';
import LandingPage from '../pages/LandingPage/LandingPage';
import Login from '../pages/Login/Login';
import ProjectDashboard from '../pages/ProjectDashboard/ProjectDashboard';
import Register from '../pages/Register/Register';
import ApiKeysPage from '../pages/TenantDashboard/ApiKeysPage/ApiKeysPage';
import TenantDashboard from '../pages/TenantDashboard/TenantDashboard';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/documentation" component={Documentation} />
        <AuthRoute exact path="/dashboard" component={TenantDashboard} />
        <AuthRoute exact path="/dashboard/api-keys" component={ApiKeysPage} />
        <AuthRoute
          exact
          path="/dashboard/:projectId"
          render={(props) => <ProjectDashboard projectId={props.match.params.projectId} />}
        />
        <AuthRoute
          exact
          path="/calendar/:projectId/"
          render={(props) => <Calendar projectId={props.match.params.projectId} />}
        />

        {/* TODO: refactor the below pages */}
        <Route exact path="/old-landing-page" component={LandingPage} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/clinics/new/" component={NewClinic} />
        <Route exact path="/register" component={Register} />
      </Switch>
    </BrowserRouter>
  );
}
