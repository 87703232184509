// Generated by protoc-gen-grpc-ts-web. DO NOT EDIT!
/* eslint-disable */
/* tslint:disable */

import * as jspb from 'google-protobuf';
import * as grpcWeb from 'grpc-web';

export class ApiKeysService {

	private client_ = new grpcWeb.GrpcWebClientBase({
		format: 'text',
	});

	private methodInfoListApiKeys = new grpcWeb.MethodDescriptor<ListApiKeysReq, ListApiKeysRes>(
		"ListApiKeys",
		null,
		ListApiKeysReq,
		ListApiKeysRes,
		(req: ListApiKeysReq) => req.serializeBinary(),
		ListApiKeysRes.deserializeBinary
	);

	private methodInfoCreateApiKey = new grpcWeb.MethodDescriptor<CreateApiKeyReq, CreateApiKeyRes>(
		"CreateApiKey",
		null,
		CreateApiKeyReq,
		CreateApiKeyRes,
		(req: CreateApiKeyReq) => req.serializeBinary(),
		CreateApiKeyRes.deserializeBinary
	);

	private methodInfoDeleteApiKey = new grpcWeb.MethodDescriptor<DeleteApiKeyReq, DeleteApiKeyRes>(
		"DeleteApiKey",
		null,
		DeleteApiKeyReq,
		DeleteApiKeyRes,
		(req: DeleteApiKeyReq) => req.serializeBinary(),
		DeleteApiKeyRes.deserializeBinary
	);

	constructor(
		private hostname: string,
		private defaultMetadata?: () => grpcWeb.Metadata,
	) { }

	listApiKeys(req: ListApiKeysReq.AsObject, metadata?: grpcWeb.Metadata): Promise<ListApiKeysRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = ListApiKeysReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.ApiKeysService/ListApiKeys',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoListApiKeys,
				(err: grpcWeb.Error, res: ListApiKeysRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	createApiKey(req: CreateApiKeyReq.AsObject, metadata?: grpcWeb.Metadata): Promise<CreateApiKeyRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = CreateApiKeyReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.ApiKeysService/CreateApiKey',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoCreateApiKey,
				(err: grpcWeb.Error, res: CreateApiKeyRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	deleteApiKey(req: DeleteApiKeyReq.AsObject, metadata?: grpcWeb.Metadata): Promise<DeleteApiKeyRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = DeleteApiKeyReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.ApiKeysService/DeleteApiKey',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoDeleteApiKey,
				(err: grpcWeb.Error, res: DeleteApiKeyRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

}




export declare namespace ListApiKeysReq {
	export type AsObject = {
	}
}

export class ListApiKeysReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListApiKeysReq.repeatedFields_, null);
	}


	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListApiKeysReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListApiKeysReq.AsObject {
		let f: any;
		return {
		};
	}

	static serializeBinaryToWriter(message: ListApiKeysReq, writer: jspb.BinaryWriter): void {
	}

	static deserializeBinary(bytes: Uint8Array): ListApiKeysReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListApiKeysReq();
		return ListApiKeysReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListApiKeysReq, reader: jspb.BinaryReader): ListApiKeysReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ListApiKeysRes {
	export type AsObject = {
		items: Array<ApiKeyDTO.AsObject>,
	}
}

export class ListApiKeysRes extends jspb.Message {

	private static repeatedFields_ = [
		1,
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListApiKeysRes.repeatedFields_, null);
	}


	getItems(): Array<ApiKeyDTO> {
		return jspb.Message.getRepeatedWrapperField(this, ApiKeyDTO, 1);
	}

	setItems(value: Array<ApiKeyDTO>): void {
		(jspb.Message as any).setRepeatedWrapperField(this, 1, value);
	}

	addItems(value?: ApiKeyDTO, index?: number): ApiKeyDTO {
		return jspb.Message.addToRepeatedWrapperField(this, 1, value, ApiKeyDTO, index);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListApiKeysRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListApiKeysRes.AsObject {
		let f: any;
		return {
			items: this.getItems().map((item) => item.toObject()),
		};
	}

	static serializeBinaryToWriter(message: ListApiKeysRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getItems();
		if (field1.length > 0) {
			writer.writeRepeatedMessage(1, field1, ApiKeyDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ListApiKeysRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListApiKeysRes();
		return ListApiKeysRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListApiKeysRes, reader: jspb.BinaryReader): ListApiKeysRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ApiKeyDTO();
				reader.readMessage(field1, ApiKeyDTO.deserializeBinaryFromReader);
				message.addItems(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateApiKeyReq {
	export type AsObject = {
		name: string,
	}
}

export class CreateApiKeyReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateApiKeyReq.repeatedFields_, null);
	}


	getName(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setName(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateApiKeyReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateApiKeyReq.AsObject {
		let f: any;
		return {
			name: this.getName(),
		};
	}

	static serializeBinaryToWriter(message: CreateApiKeyReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getName();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateApiKeyReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateApiKeyReq();
		return CreateApiKeyReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateApiKeyReq, reader: jspb.BinaryReader): CreateApiKeyReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setName(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateApiKeyRes {
	export type AsObject = {
		apiKey?: ApiKeyDTO.AsObject,
	}
}

export class CreateApiKeyRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateApiKeyRes.repeatedFields_, null);
	}


	getApiKey(): ApiKeyDTO {
		return jspb.Message.getWrapperField(this, ApiKeyDTO, 1);
	}

	setApiKey(value?: ApiKeyDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateApiKeyRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateApiKeyRes.AsObject {
		let f: any;
		return {
			apiKey: (f = this.getApiKey()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: CreateApiKeyRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getApiKey();
		if (field1 != null) {
			writer.writeMessage(1, field1, ApiKeyDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateApiKeyRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateApiKeyRes();
		return CreateApiKeyRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateApiKeyRes, reader: jspb.BinaryReader): CreateApiKeyRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ApiKeyDTO();
				reader.readMessage(field1, ApiKeyDTO.deserializeBinaryFromReader);
				message.setApiKey(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace DeleteApiKeyReq {
	export type AsObject = {
		id: string,
	}
}

export class DeleteApiKeyReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, DeleteApiKeyReq.repeatedFields_, null);
	}


	getId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		DeleteApiKeyReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): DeleteApiKeyReq.AsObject {
		let f: any;
		return {
			id: this.getId(),
		};
	}

	static serializeBinaryToWriter(message: DeleteApiKeyReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): DeleteApiKeyReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new DeleteApiKeyReq();
		return DeleteApiKeyReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: DeleteApiKeyReq, reader: jspb.BinaryReader): DeleteApiKeyReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace DeleteApiKeyRes {
	export type AsObject = {
	}
}

export class DeleteApiKeyRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, DeleteApiKeyRes.repeatedFields_, null);
	}


	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		DeleteApiKeyRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): DeleteApiKeyRes.AsObject {
		let f: any;
		return {
		};
	}

	static serializeBinaryToWriter(message: DeleteApiKeyRes, writer: jspb.BinaryWriter): void {
	}

	static deserializeBinary(bytes: Uint8Array): DeleteApiKeyRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new DeleteApiKeyRes();
		return DeleteApiKeyRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: DeleteApiKeyRes, reader: jspb.BinaryReader): DeleteApiKeyRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ApiKeyDTO {
	export type AsObject = {
		id: string,
		name: string,
		key: string,
	}
}

export class ApiKeyDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ApiKeyDTO.repeatedFields_, null);
	}


	getId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getName(): string {return jspb.Message.getFieldWithDefault(this, 2, "");
	}

	setName(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 2, value);
	}

	getKey(): string {return jspb.Message.getFieldWithDefault(this, 3, "");
	}

	setKey(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 3, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ApiKeyDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ApiKeyDTO.AsObject {
		let f: any;
		return {
			id: this.getId(),
			name: this.getName(),
			key: this.getKey(),
		};
	}

	static serializeBinaryToWriter(message: ApiKeyDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getName();
		if (field2.length > 0) {
			writer.writeString(2, field2);
		}
		const field3 = message.getKey();
		if (field3.length > 0) {
			writer.writeString(3, field3);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ApiKeyDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ApiKeyDTO();
		return ApiKeyDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ApiKeyDTO, reader: jspb.BinaryReader): ApiKeyDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			case 2:
				const field2 = reader.readString()
				message.setName(field2);
				break;
			case 3:
				const field3 = reader.readString()
				message.setKey(field3);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}


function ListApiKeysReqFromObject(obj: ListApiKeysReq.AsObject | undefined): ListApiKeysReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListApiKeysReq();
	return message;
}

function ListApiKeysResFromObject(obj: ListApiKeysRes.AsObject | undefined): ListApiKeysRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListApiKeysRes();
	(obj.items || [])
		.map((item) => ApiKeyDTOFromObject(item))
		.forEach((item) => message.addItems(item));
	return message;
}

function ApiKeyDTOFromObject(obj: ApiKeyDTO.AsObject | undefined): ApiKeyDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ApiKeyDTO();
	message.setId(obj.id);
	message.setName(obj.name);
	message.setKey(obj.key);
	return message;
}

function CreateApiKeyReqFromObject(obj: CreateApiKeyReq.AsObject | undefined): CreateApiKeyReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateApiKeyReq();
	message.setName(obj.name);
	return message;
}

function CreateApiKeyResFromObject(obj: CreateApiKeyRes.AsObject | undefined): CreateApiKeyRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateApiKeyRes();
	message.setApiKey(ApiKeyDTOFromObject(obj.apiKey));
	return message;
}

function DeleteApiKeyReqFromObject(obj: DeleteApiKeyReq.AsObject | undefined): DeleteApiKeyReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new DeleteApiKeyReq();
	message.setId(obj.id);
	return message;
}

function DeleteApiKeyResFromObject(obj: DeleteApiKeyRes.AsObject | undefined): DeleteApiKeyRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new DeleteApiKeyRes();
	return message;
}

