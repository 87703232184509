import { CircularProgress } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { observer } from 'mobx-react';
import React from 'react';

interface Props {
  loading: boolean;
  error?: Error;
}

const LoadingIndicator = (props: Props) => {
  if (props.error) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icons.Error color="error" style={{ marginRight: 8 }} />
        <span>{props.error.message}</span>
      </div>
    );
  }
  if (!props.loading) {
    return null;
  }
  return <CircularProgress size={60} thickness={7} />;
};

export default observer(LoadingIndicator);
