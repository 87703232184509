import { observer } from 'mobx-react';
import React from 'react';
import { sharedUserService } from '../../Lib/authentication/UserService';
import AppShell, { AppShellProps } from '../../Lib/ui-kit/AppShell';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends AppShellProps {}

// TODO: use user store
const isSignedIn = true;

const Layout = (props: Props) => {
  const { title, menuOptions, ...extraProps } = props;

  // default to an empty list
  // and make a copy of the array so we can
  // modify it without mutating our input props
  const menu = [...(menuOptions ?? [])];

  if (isSignedIn) {
    menu.push({
      title: 'Sign Out',
      onClick: () => sharedUserService.signOut(),
    });
  }

  return (
    <AppShell title={title} menuOptions={menu} {...extraProps}>
      {props.children}
    </AppShell>
  );
};

export default observer(Layout);
