import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Input,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { FormEvent, useState } from 'react';
import useOnceAsync from '../../../../Lib/hooks/UseOnceAsync';
import { ApiKeyDTO } from '../../../../Lib/sdk/ApiKeys_pb';
import HiddenField from '../../../../Lib/ui-kit/HiddenField';
import LoadingIndicator from '../../../../Lib/ui-kit/LoadingIndicator';
import Layout from '../../../components/Layout';
import grpc from '../../../grpc';
import TenantDashboardNav from '../TenantDashboardNav';

const ApiKeysPage = () => {
  const { value, error, loading, refresh } = useOnceAsync(async () => {
    return await grpc.apikeys.listApiKeys({});
  });

  const [draftKeyName, setDraftKeyName] = useState('');

  const onCreate = async (event: FormEvent) => {
    event.preventDefault();
    await grpc.apikeys.createApiKey({
      name: draftKeyName,
    });
    setDraftKeyName('');
    await refresh();
  };

  const onDelete = async (key: ApiKeyDTO.AsObject) => {
    await grpc.apikeys.deleteApiKey({
      id: key.id,
    });
    await refresh();
  };

  return (
    <Layout title="Tenant Dashboard" drawer={TenantDashboardNav}>
      <Typography variant="h1">Api Keys</Typography>

      <LoadingIndicator loading={loading} error={error} />

      {value && (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Key</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {value.items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell component="th" scope="row">
                      {item.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <HiddenField value={item.key} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Button onClick={() => onDelete(item)}>Delete</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <form onSubmit={onCreate}>
            <Card style={{ maxWidth: 350 }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Create an Api Key
                </Typography>
                <FormControl>
                  <InputLabel>name...</InputLabel>
                  <Input
                    value={draftKeyName}
                    onChange={(event) => setDraftKeyName(event.currentTarget.value)}
                  />
                </FormControl>
              </CardContent>
              <CardActions>
                <Button type="submit" size="small" color="primary">
                  Create
                </Button>
              </CardActions>
            </Card>
          </form>
        </>
      )}
    </Layout>
  );
};

export default ApiKeysPage;
