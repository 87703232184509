require('typeface-roboto');
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.scss';
import App from './app/App';
import { environment } from './utils/Environment';

if (environment.sentry.enabled && environment.sentry.dsn) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    environment: environment.environment,
    integrations: [new Integrations.BrowserTracing({})],
    attachStacktrace: true,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
