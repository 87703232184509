import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import urljoin from 'url-join';
import useOnceAsync from '../../../Lib/hooks/UseOnceAsync';
import LoadingIndicator from '../../../Lib/ui-kit/LoadingIndicator';
import Layout from '../../components/Layout';
import grpc from '../../grpc';
import TenantDashboardNav from './TenantDashboardNav';

const TenantDashboard = () => {
  const { value, error, loading } = useOnceAsync(async () => {
    return await grpc.projects.listProjects({});
  });

  return (
    <Layout title="Tenant Dashboard" drawer={TenantDashboardNav}>
      <Typography variant="h1">Locations</Typography>

      <LoadingIndicator loading={loading} error={error} />

      {value && (
        <List>
          {value.items.map((project) => (
            <Link key={project.id} to={urljoin('/dashboard', project.id)}>
              <ListItem>
                <ListItemIcon>
                  <NavigateNext />
                </ListItemIcon>
                <ListItemText primary={project.name} />
              </ListItem>
            </Link>
          ))}
        </List>
      )}
    </Layout>
  );
};

export default observer(TenantDashboard);
