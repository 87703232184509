import {
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  CardContent,
  Button,
  Typography,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import urljoin from 'url-join';
import useOnceAsync from '../../../Lib/hooks/UseOnceAsync';
import LoadingIndicator from '../../../Lib/ui-kit/LoadingIndicator';
import Layout from '../../components/Layout';
import grpc from '../../grpc';

interface Props {
  projectId: string;
}

const ProjectDashboard = (props: Props) => {
  const { value, error, loading } = useOnceAsync(async () => {
    return await grpc.projects.getProject({ projectId: props.projectId });
  });

  return (
    <Layout title="Project Dashboard">
      <Typography variant="h1">{value?.project?.name}</Typography>

      <LoadingIndicator loading={loading} error={error} />

      {value?.project && (
        <Card style={{ width: 400 }}>
          <CardHeader
            title="Calendar"
            subtitle="Booking calendar view"
            avatar={<Icons.CalendarToday />}
          />
          <CardMedia>
            <img
              width={400}
              height={250}
              src="https://via.placeholder.com/400x250.png?text=Booking+Calendar+Screenshot"
            />
          </CardMedia>

          <CardContent>
            <Typography variant="h5">Calendar</Typography>
            <Typography>Booking calendar view</Typography>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec mattis pretium massa.
              Aliquam erat volutpat. Nulla facilisi.
            </Typography>
          </CardContent>

          <CardActions>
            <Link to={urljoin('/calendar', value.project.id)}>
              <Button color="primary">View</Button>
            </Link>
            <Button>Organise</Button>
          </CardActions>
        </Card>
      )}
    </Layout>
  );
};

export default observer(ProjectDashboard);
