import { observer } from 'mobx-react';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { sharedUserService } from '../authentication/UserService';

export const AuthRoute = observer((props: RouteProps) => {
  const { component, render, ...rest } = props;

  if (!sharedUserService.token) {
    return (
      <Route {...rest}>
        <Redirect to="/login" />
      </Route>
    );
  }

  return <Route {...rest} component={component} render={render} />;
});
