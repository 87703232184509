import {
  AppBar,
  createStyles,
  makeStyles,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import ResponsiveDrawer from './ResponsiveDrawer';

export interface AppShellProps {
  title: string;
  children: React.ReactNode;
  menuOptions?: MenuOption[];
  drawer?: React.ReactNode;
}

export interface MenuOption {
  onClick: () => void;
  title: string;
}

const AppShell = (props: AppShellProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6">{props.title}</Typography>

          {props.menuOptions?.map((menuOption, index) => {
            return (
              <MenuItem key={index} onClick={menuOption.onClick}>
                {menuOption.title}
              </MenuItem>
            );
          })}
        </Toolbar>
      </AppBar>

      {props.drawer && (
        <nav className={classes.drawer} aria-label="mailbox folders">
          <ResponsiveDrawer>{props.drawer}</ResponsiveDrawer>
        </nav>
      )}

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
      zIndex: 0,
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);

export default observer(AppShell);
