import { Container, Typography } from '@material-ui/core';
import * as React from 'react';
import Router from '../navigation/Router';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export default function App() {
  return (
    <ErrorBoundary>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router />
      </MuiPickersUtilsProvider>
    </ErrorBoundary>
  );
}
interface IErrorBoundaryState {
  crashed: boolean;
  error?: Error;
}

class ErrorBoundary extends React.Component<{ children: React.ReactNode }, IErrorBoundaryState> {
  state: IErrorBoundaryState = {
    crashed: false,
    error: undefined,
  };

  static getDerivedStateFromError(error: Error): IErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { crashed: true, error };
  }

  componentDidCatch(error: Error) {
    // eslint-disable-next-line no-console
    console.log('Error caught in Error Boundary', error);
  }

  render() {
    if (this.state.crashed) {
      return (
        <Container>
          <Typography variant="h1">Something went wrong</Typography>
        </Container>
      );
    }
    return <div>{this.props.children}</div>;
  }
}
