import { metadata, registerDevTools } from '../Lib/grpc';
import { ApiKeysService } from '../Lib/sdk/ApiKeys_pb';
import { AvailabilitiesService } from '../Lib/sdk/Availabilities_pb';
import { BookingsService } from '../Lib/sdk/Bookings_pb';
import { ProjectsService } from '../Lib/sdk/Projects_pb';
import { ResourcesService } from '../Lib/sdk/Resources_pb';
import { environment } from './utils/Environment';

const host = environment.apiUrl;

const grpc = {
  projects: new ProjectsService(host, metadata),
  bookings: new BookingsService(host, metadata),
  availabilities: new AvailabilitiesService(host, metadata),
  resources: new ResourcesService(host, metadata),
  apikeys: new ApiKeysService(host, metadata),
};

registerDevTools(grpc);

export default grpc;
