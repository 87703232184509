import { Card, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import Layout from '../../components/Layout';

interface Props {
  projectId: string;
}

// eslint-disable-next-line
const Calendar = (props: Props) => {
  return (
    <Layout title="Calendar">
      <Card>
        <Typography variant="h1">Calendar</Typography>
      </Card>
    </Layout>
  );
};

export default observer(Calendar);
